<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma" type="primary" @click="showAddModel">新增风采</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" align="center" label="姓名" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="电话" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="职位" show-overflow-tooltip />
                <el-table-column show-overflow-tooltip prop="name" align="center" label="创建时间" />
                <el-table-column align="center" label="操作" width="130">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button class="mr10" style="color:#F56C6C" type="text" @click="showUpdateModel(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel" :destroy-on-close="true"
            width="600px" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
                <el-form-item label="姓 名" prop="name">
                    <el-col :xl="10">
                        <el-input placeholder="请输入姓名" v-model="ruleForm.name" clearable />
                    </el-col>
                </el-form-item>
                <el-form-item label="电 话" prop="phone">
                    <el-col :xl="10">
                        <el-input placeholder="请输入电话" v-model="ruleForm.phone" clearable />
                    </el-col>
                </el-form-item>
                <el-form-item label="职 位" prop="position">
                    <el-col :xl="10">
                        <el-input placeholder="请输入职位" v-model="ruleForm.position" clearable />
                    </el-col>
                </el-form-item>
                <el-form-item label="员工照片" prop="photos">
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="handProgress"
                        :on-success="handleSuccess" :on-remove="handleRemove" :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>建议上传150x150，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-form-item label="是否选择所有园区">
                    <el-radio-group v-model="isSelectArea">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="省市区" v-if="isSelectArea==0">
                    <el-select class="w130" v-model="ruleForm.province" placeholder="省">
                        <el-option v-for="item in provinceData" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="ruleForm.city" placeholder="市">
                        <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="ruleForm.area" placeholder="区">
                        <el-option v-for="item in areaData" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="园 区" v-if="isSelectArea==0">
                    <el-checkbox-group v-model="park">
                        <el-checkbox v-for="(item, index) in parkData"
                            :key="index" :label="item.id">{{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "propertyStyleTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //新增/修改配置
            provinceData: [{
                value: '选项1',
                label: '黄金糕'
            }],
            areaData: [{
                value: '选项1',
                label: '黄金糕'
            }],
            cityData: [{
                value: '选项1',
                label: '黄金糕'
            }],
            disabled: false,
            ruleForm: {
                name: "",
                phone: "",
                position: "",
                photos: "",
                province: "",
                city: "",
                area: "",
                park:"",
            },
            park: [],
            parkData: [{
                id: 1,
                label: '黄金糕'
            }],
            fileList: [],
            rules: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
                photos: [{ required: true }],
            },
            modelTitle: "",
            formType: "",
            updateId: 0,
            isSelectArea: 1,

            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [{ name: "测试" }],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
        };
    },
    computed: {
        uploadDisabled() {
            return this.ruleForm.photos !== "";
        },
    },
    filters: {

    },
    created() {

    },
    methods: {
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增物业风采";
            this.formType = "add";
            this.ruleForm = {
                name: "",
                phone: "",
                position: "",
                photos: ""
            }
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑物业风采";
            this.formType = "update";
            this.updateId = val.id;
            this.ruleForm = {
                name: "",
                phone: "",
                position: "",
                photos: ""
            }
        },
        /**@method 删除图片 */
        handleRemove(file, fileList) {
            //-console.log(file, fileList);
            this.ruleForm.photos = "";
        },
        /**@method 图片上传 */
        handProgress(event, file, fileList) {
            
        },
        /**@method 上传回调 */
        handleSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.photos = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**提交内容 */
        submitForm(payload) {

            if (!this.ruleForm.photos) {
                return this.$message.error('请上传文章封面')
            }
            if (!this.ruleForm.details) {
                return this.$message.error('请填写文章内容')
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.ruleForm));
                    this.$message({
                        message: "施工中...",
                        type: "success"
                    })
                } else {
                    //-console.log("error submit!!");
                    return false;
                }
            });
        },
        /**@method 搜索 */
        searchFun() { },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage
            };
            try {
                this.loading = true;

                this.loading = false;

                // const { data } = result;
                // this.tableData = data.pageInfo.list;
                // this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>